import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useWindowSize } from '@hooks/useWindowSize';
import Modal from '@components/Modal/Modal';
import DesktopLayout from './DesktopLayout/DesktopLayout';
import MobileLayout from './MobileLayout/MobileLayout';
import SpinnerIcon from '@components/Icons/SpinnerIcon';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import { validation } from './validation';
import { normalize } from './utilities';
import { formatters, applyFormatters } from './formatters';
import styles from './SizingModal.module.scss';
import { useSelector } from "react-redux";
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";
import AddRowButton from "@components/SizingModal/AddRowButton/AddRowButton";
import SubmitButton from "@components/SizingModal/SubmitButton/SubmitButton";

const SizingModal = ({
	quoteId,
	productLineId = null,
	productIds = [],
	active = false,
	onClose = () => { },
	className = null,
	hideBackButton = false,
	isEditable = true,
	allowVirtualSizer = false,
}) => {


	const dimensions = useWindowSize();

	// loaders
	const [isFetching, setIsFetching] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	// form validation UI toggle
	const [highlightIncomplete, setHighlightIncomplete] = useState(true);
	const scrollNode = useRef(null);
	const [addTrigger, setAddTrigger] = useState(false);

	// form schema
	const [schema, setSchema] = useState({});

	// integer value ranges
	const [ranges, setRanges] = useState({});

	// lettering field count
	const [letteringFieldCount, setLetteringFieldCount] = useState(0);

	// sizing data
	const [data, setData] = useState([]);

	const [isPristine, setIsPristine] = useState(true);
	const [confirmModalActive, setConfirmModalActive] = useState(false);
	const [onConfirmRefresh, setOnConfirmRefresh] = useState(false);
	const [refreshToken, setRefreshToken] = useState(false);
	const instructions = useSelector(
		(state) => state.staticData.orderSettings['sizing_instructions'] || "...",
	);

	const [isSizeZero, setIsSizeZero] = useState({
		coat: [],
		pants: [],
		coveralls: [],
	});

	const isCSRUser = useSelector((state) =>
		state.auth.user?.permissions?.includes('csr_access'),
	);


	/**
	 * Fetch Quote Sizing Data
	 */

	useEffect(() => {
		if (!active) {
			return;
		}

		setIsFetching(true);

		let letteringFieldCount = 0;

		(async () => {
			// get quote lettering fields

			const letteringFields = await http()
				.get(`${API_ROOT}/sizing/lettering/${quoteId}`)
				.then(({ data }) => {
					return data.lettering_fields;
				});
			// get sizing fields that do not meet product option requirements
			const missingRequiredOptions = await http()
				.get(`${API_ROOT}/sizing/missing-sizing-product-option/${quoteId}`)
				.then(({ data }) => {
					return data.sizing_fields.map((field) => {
						return field.sizing_field_id;
					});
				});
			// get product line sizing schema
			const [_schema, ranges] = await http()
				.get(`${API_ROOT}/product-lines/sizing/${productLineId}`)
				.then(({ data }) => {
					const schema = data.sizing_fields.filter(field => {
						if (missingRequiredOptions.some(sizingFieldId => sizingFieldId === field.sizing_field_id)) {
							return false;
						}
						if (!field.sizing_field_lettering_requirement) {
							// Increase sizing sheet left column if field has no product assigned, scaled by sizing field title length
							if (field.sizing_field_name !== 'name' && field.sizing_field_name !== 'email' && field.product_id === null) {	
								letteringFieldCount += field.sizing_field_title.length / 6;
							}
							return true;
						}
						if (letteringFields.some(lf => lf.quote_product_option_value_lettering_type === field.sizing_field_lettering_requirement
							&& (!field.product_id || lf.product_id === field.product_id)
						)) {
							letteringFieldCount += 1;
							return true;
						}
						return false;
					}).reduce(
						(result, field) => {
							if (
								!field.product_id ||
								productIds.includes(field.product_id)
							) {
								result[field.sizing_field_name] = field;
								const foundLettering = letteringFields.find(lf => lf.sizing_field_name === field.sizing_field_name)

								if (foundLettering) {
									result[field.sizing_field_name].lettering_max_chars = foundLettering.lettering_character_length_max_characters
								}
							}
							return result;
						},
						{},
					);
					setLetteringFieldCount(letteringFieldCount);

					// define min/max lengths
					const ranges = data.ranges.reduce((result, range) => {
						result[range.sizing_field_name] = [
							parseInt(range.sizing_field_validation_min),
							parseInt(range.sizing_field_validation_max),
						];

						return result;
					}, {});

					return [schema, ranges];
				})
				.catch(() => {
					setIsFetching(false);
					return;
				});

			setSchema(_schema);
			setRanges(ranges);

			// get quote sizing data
			await http()
				.get(`${API_ROOT}/sizing/${quoteId}`)
				.then(({ data }) => {
					if (data.quote_sizing.length) {
						let row = 0;
						let zeroSizes = {
							coat: [],
							pants: [],
							coveralls: [],
						};
						for (const quote_item of data.quote_sizing) {
							if (quote_item.quantity_pants == 0) {
								zeroSizes.pants.push(row);
							}
							if (quote_item.quantity_coat == 0) {
								zeroSizes.coat.push(row);
							}
							if (quote_item.quantity_coveralls == 0) {
								zeroSizes.coveralls.push(row);
							}
							row++;
						}

						setIsSizeZero(zeroSizes);

						let formattedData = applyFormatters(_schema, data.quote_sizing);

						formattedData.map((item, index) => {
							for (let sizingFieldName in _schema) {
								if (!_schema[sizingFieldName] || !Array.isArray(_schema[sizingFieldName]?.conditions) || !_schema[sizingFieldName]?.conditions?.length) {
									continue;
								}
								for (let condition of _schema[sizingFieldName].conditions) {
									if (_schema[sizingFieldName].sizing_field_is_beg == 1) {
										const begChestComparisonTypes = _schema[sizingFieldName].sizing_field_beg_chest_compare_to.split('-');
										const begChestCompareSizingFieldNames = Object.keys(_schema).filter(sizingFieldKey => {
											return sizingFieldKey.includes(begChestComparisonTypes[0]) || sizingFieldKey.includes(begChestComparisonTypes[1]) || sizingFieldKey.includes(begChestComparisonTypes[2]);
										});
										// Always show beg if conditions apply
										let clearBeg = true;
										if (formattedData[index]?.chest_size) {
											if (
												(formattedData[index][begChestCompareSizingFieldNames[0]] && (parseInt(formattedData[index][begChestCompareSizingFieldNames[0]]) - parseInt(formattedData[index].chest_size) >= (_schema[sizingFieldName].sizing_field_beg_small ? parseInt(_schema[sizingFieldName].sizing_field_beg_small) : 3)))
												|| (formattedData[index][begChestCompareSizingFieldNames[1]] && (parseInt(formattedData[index][begChestCompareSizingFieldNames[1]]) - parseInt(formattedData[index].chest_size) >= (_schema[sizingFieldName].sizing_field_beg_small ? parseInt(_schema[sizingFieldName].sizing_field_beg_small) : 3)))
												|| (formattedData[index][begChestCompareSizingFieldNames[2]] && (parseInt(formattedData[index][begChestCompareSizingFieldNames[2]]) - parseInt(formattedData[index].chest_size) >= (_schema[sizingFieldName].sizing_field_beg_small ? parseInt(_schema[sizingFieldName].sizing_field_beg_small) : 3)))
											) {

												setSchema((_schema) => {
													_schema[sizingFieldName].recommendations[index] = { recommendation_type: 'single', recommendation_text: _schema[sizingFieldName].sizing_field_recommendation };
													return { ..._schema };
												});
												clearBeg = false;

											}
										}
										if (clearBeg) {
											setSchema((_schema) => {
												if (_schema[sizingFieldName].recommendations[index]) {
													_schema[sizingFieldName].recommendations[index] = undefined;
												}

												return { ..._schema };
											});
										}
									} else if (condition.sizing_field_option_id) {
										const conditionOptionIndex = _schema[sizingFieldName].options.findIndex((option) => option.sizing_field_option_id === condition.sizing_field_option_id);

										if (item[condition.sizing_field_name] === _schema[sizingFieldName].options[conditionOptionIndex].sizing_field_option_value) {
											disableFieldOrOptions(condition, index, _schema);
											if(condition.sizing_field_condition_type === 'disable field') {
												data.quote_sizing[index][condition.second_sizing_field_name] = undefined;
												formattedData[index][condition.second_sizing_field_name] = undefined;
											} else {
												for (let conditionOption of condition.options) {
													if (data.quote_sizing[index][condition.second_sizing_field_name] === conditionOption.sizing_field_option_value) {
														data.quote_sizing[index][condition.second_sizing_field_name] = undefined;
														formattedData[index][condition.second_sizing_field_name] = undefined;
													}
												}
											}
										}

									} else if (condition.comparison_sizing_field_id) {
										let conditionTrue = false;
										switch (condition.sizing_field_condition_comparison_type) {
											case 'greater than':
												conditionTrue = parseInt(item[condition.sizing_field_name]) > (parseInt(item[condition.comparison_sizing_field_name]) + parseInt(condition.sizing_field_condition_modifier));
												break;
											case 'greater than or equal to':
												conditionTrue = parseInt(item[condition.sizing_field_name]) >= (parseInt(item[condition.comparison_sizing_field_name]) + parseInt(condition.sizing_field_condition_modifier));
												break;
											case 'equal to':
												conditionTrue = parseInt(item[condition.sizing_field_name]) === (parseInt(item[condition.comparison_sizing_field_name]) + parseInt(condition.sizing_field_condition_modifier));
												break;
											case 'less than':
												conditionTrue = parseInt(item[condition.sizing_field_name]) < (parseInt(item[condition.comparison_sizing_field_name]) + parseInt(condition.sizing_field_condition_modifier));
												break;
											case 'less than or equal to':
												conditionTrue = parseInt(item[condition.sizing_field_name]) <= (parseInt(item[condition.comparison_sizing_field_name]) + parseInt(condition.sizing_field_condition_modifier));
										}

										if (conditionTrue) {
											disableFieldOrOptions(condition, index, _schema);
											if(condition.sizing_field_condition_type === 'disable field') {
												data.quote_sizing[index][condition.second_sizing_field_name] = undefined;
												formattedData[index][condition.second_sizing_field_name] = undefined;
											} else {
												for (let conditionOption of condition.options) {
													if (data.quote_sizing[index][condition.second_sizing_field_name] === conditionOption.sizing_field_option_value) {
														data.quote_sizing[index][condition.second_sizing_field_name] = undefined;
														formattedData[index][condition.second_sizing_field_name] = undefined;
													}
												}
											}
										}
									} else if (condition.sizing_field_condition_value) {
										if (item[condition.sizing_field_name] === condition.sizing_field_condition_value) {
											disableFieldOrOptions(condition, index, _schema);
											if(condition.sizing_field_condition_type === 'disable field') {
                                                data.quote_sizing[index][condition.second_sizing_field_name] = undefined;
												formattedData[index][condition.second_sizing_field_name] = undefined;
											} else {
												for (let conditionOption of condition.options) {
													if (data.quote_sizing[index][condition.second_sizing_field_name] === conditionOption.sizing_field_option_value) {
														data.quote_sizing[index][condition.second_sizing_field_name] = undefined;
														formattedData[index][condition.second_sizing_field_name] = undefined;
													}
												}
											}
										}
									}
								}
							}
						});

						setData(applyFormatters(_schema, data.quote_sizing));
					} else {
						setData([
							Object.fromEntries(
								Object.keys(_schema).map((key) => {
									if (key === 'quantity_pants' || key === 'quantity_coat' || key === 'quantity_coveralls') {
										return [key, 1];
									}

									if (_schema[key].sizing_field_type === 'dropdown') {
										const defaultOption = _schema[key].options.find(option => option.sizing_field_option_is_default === '1');
										if (defaultOption) {
											return [key, defaultOption.sizing_field_option_value]
										}
									}

									return [key, ''];
								}),
							),
						]);
					}
				})
				.finally(() => {
					setIsFetching(false);
				});
		})();
	}, [quoteId, productLineId, active, productIds, refreshToken]);

	function disableFieldOrOptions (condition, index, _schema, toggle = true) {
		if (!_schema[condition.second_sizing_field_name]) {
			return;
		}
        if(condition.sizing_field_condition_type === 'disable field') {
            setSchema((schema) => {
                _schema[condition.second_sizing_field_name].disabledIndexes = {
					..._schema[condition.second_sizing_field_name].disabledIndexes,
					[index]: toggle
                };
                return {...schema};
            });

			if(toggle) {
                setData((data) =>
                    data.map((item, i) => {
                        return i === index ? { ...item, [condition.second_sizing_field_name]: undefined } : item;
                    }),
                );
            }
        } else {
            for (let conditionOption of condition.options) {
                const conditionOptionIndex = _schema[condition.second_sizing_field_name].options.findIndex((option) => option.sizing_field_option_id === conditionOption.sizing_field_option_id);
                setSchema((schema) => {
                    _schema[condition.second_sizing_field_name].options[conditionOptionIndex].disabledIndexes = {
                        ..._schema[condition.second_sizing_field_name].options[conditionOptionIndex].disabledIndexes,
                        [index]: toggle
                    };
                    return {...schema};
                });
				if (toggle) {
                    setData((data) =>
                        data.map((item, i) => {
							if (i === index && item[condition.second_sizing_field_name]
								&& item[condition.second_sizing_field_name] === conditionOption.sizing_field_option_value) {
								return { ...item, [condition.second_sizing_field_name]: undefined }
							}
                            return item;
                        }),
                    );
				}
            }
        }
	}

	const incomplete = () => {
		
		if (!isEditable && !isCSRUser) {
			return [];
		}
		console.log('incomplete');
		return data.reduce((result, item, index) => {
			return [
				...result,
				Object.keys(schema).filter((key) => {
					if (
						key !== 'name'
						&& key !== 'quantity_pants'
						&& key !== 'quantity_coat'
						&& key !== 'quantity_coveralls'
					) {
						if (schema[key].product_title === 'Pant' && isSizeZero.pants.includes(index)) {
							return false;
						}

						if (schema[key].product_title === 'Coat' && isSizeZero.coat.includes(index)) {
							return false;
						}

						if (schema[key].product_title === 'Coveralls' && isSizeZero.coveralls.includes(index)) {
							return false;
						}
					}
					const valid = validation(schema[key], item[key], data[index]);
					if(!valid){
						console.log('valid', valid);
					}
					return !validation(schema[key], item[key], data[index]);
				}),
			];
		}, []);
	};

	/**
	 * Track Invalid Fields
	 */

	const invalid = useMemo(() => {
		return data.reduce((result, item, index) => {
			return [
				...result,
				[
					...Object.keys(ranges).filter((key) => {
						const value = item[key]?.toString()?.replace(/[^.\d]/g, '');
						console.log(key)
						if(key === 'email'){
							return false;
						}
						if (
							key !== 'name'
							&& key !== 'quantity_pants'
							&& key !== 'quantity_coat'
							&& key !== 'quantity_coveralls'
						) {
							if (ranges[key].product_title === 'Pant' && isSizeZero.pants.includes(index)) {
								return false;
							}

							if (ranges[key].product_title === 'Coat' && isSizeZero.coat.includes(index)) {
								return false;
							}

							if (ranges[key].product_title === 'Coveralls' && isSizeZero.coveralls.includes(index)) {
								return false;
							}
						}

						return (
							ranges[key] &&
							value &&
							(parseInt(value) < ranges[key][0] ||
								parseInt(value) > ranges[key][1])
						);
					}),
					...Object.keys(schema).filter((key) => {
						return key !== 'email' && data[index][key]?.length > (parseInt(schema[key].lettering_max_chars ?? 20));
					})
				],
			];
		}, []);
	}, [data, ranges]);


	/**
	 * Handle Modal Close
	 */

	const handleClose = useCallback(
		async (refresh = false) => {
			setIsPristine(true);
			setIsSizeZero({ coat: [], pants: [], coveralls: [] });

			return onClose(refresh, data.reduce((a, { quantity_coat }) => a + parseInt(quantity_coat), 0), data.reduce((a, { quantity_pants }) => a + parseInt(quantity_pants), 0), data.reduce((a, { quantity_coveralls }) => a + parseInt(quantity_coveralls), 0));
		},
		[onClose, data],
	);

	/**
	 * Confirm Close Modal
	 */
	const confirmClose = useCallback(async (refresh = false) => {
		if (!isPristine) {
			setOnConfirmRefresh(refresh);
			setConfirmModalActive(true);
		} else {
			await handleClose(refresh);
		}
	},
		[isPristine, handleClose]
	);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(async() => {
		if (invalid.some((a) => a.length)) {
			setHighlightIncomplete(true);
			return;
		}
		setIsSubmitting(true);

		await http()
			.post(`${API_ROOT}/sizing/${quoteId}`, {
				data: normalize(data, schema),
			})
			.then(async ({ data }) => {
				setIsPristine(true);
				setHighlightIncomplete(false);
				setData(applyFormatters(schema, data.quote_sizing));
				await handleClose(true);
				setIsSubmitting(false);
			})
			.catch(() => {
				setIsSubmitting(false);
			});
	}, [quoteId, data, schema, invalid, handleClose]);

	/**
	 * Handle Sizing Sheet Upload
	 */

	const handleUpload = useCallback(
		(data) => {
			setHighlightIncomplete(true);
			setData(applyFormatters(schema, data.quote_sizing));
		},
		[schema],
	);

	/**
	 * Handle Add Row
	 */

	const handleAddRow = useCallback((rowsToAdd) => {

		let newRows = [];
		for (let i = 0; i < rowsToAdd; i++) {
			newRows.push(Object.fromEntries(Object.keys(schema).map((key) => {
				if (key === 'quantity_pants' || key === 'quantity_coat' || key === 'quantity_coveralls') {
					return [key, 1];
				}

				if (schema[key].sizing_field_type === 'dropdown') {
					const defaultOption = schema[key].options.find(option => option.sizing_field_option_is_default === '1');
					if (defaultOption) {
						return [key, defaultOption.sizing_field_option_value]
					}
				}

				return [key, ''];
			}
			)));
		}
		setData((data) => {
			return [
				...data,
				...newRows,
			];
		});
		setAddTrigger(true);
	}, [schema]);

	const scrollToBottom = () => {
		scrollNode.current?.scrollIntoView();
	}

	useEffect(() => {
		if (addTrigger) {
			scrollToBottom();
		}
		setAddTrigger(false);
	}, [addTrigger])

	/**
	 * Handle Remove Row
	 */

	const handleRemoveRow = useCallback((index) => {
		setData((data) => data.filter((_, i) => i !== index));
	}, []);

	/**
	 * Handle Field Change
	 */

	const handleChange = useCallback((index, change, ignoreChecks = false) => {

		if (isPristine) {
			setIsPristine(false);
		}

		// set the row..
		let sizeZero = isSizeZero;
		if (parseInt(change.quantity_coat) === 0) {
			sizeZero.coat.push(index);
			setIsSizeZero(sizeZero);
			ignoreChecks = true;
		} else if (parseInt(change.quantity_pants) === 0) {
			sizeZero.pants.push(index);
			setIsSizeZero(sizeZero);
			ignoreChecks = true;
		} else if (parseInt(change.quantity_coveralls) === 0) {
			sizeZero.coveralls.push(index);
			setIsSizeZero(sizeZero);
			ignoreChecks = true;
		} else if (change.quantity_coat || change.quantity_pants || change.quantity_coveralls) {
			let itemIndex;
			if (change.quantity_coat) {
				itemIndex = sizeZero.coat.indexOf(index);

				if (itemIndex !== -1) {
					sizeZero.coat.splice(itemIndex, 1);
				}
			} else if (change.quantity_pants) {
				itemIndex = sizeZero.pants.indexOf(index);

				if (itemIndex !== -1) {
					sizeZero.pants.splice(itemIndex, 1);
				}
			} else {
				itemIndex = sizeZero.coveralls.indexOf(index);

				if (itemIndex !== -1) {
					sizeZero.coveralls.splice(itemIndex, 1);
				}
			}

			setIsSizeZero(sizeZero);
		}

		if (ignoreChecks) {
			setData((data) =>
				data.map((item, i) => {
					return i === index ? { ...item, ...change } : item;
				}),
			);
			return;
		}

		let newData = data.map((item, i) => {
			return i === index ? { ...item, ...change } : item;
		});

		for (let sizingFieldName in schema) {
			if (schema[sizingFieldName].sizing_field_is_beg == 1) {
				const begChestComparisonTypes = schema[sizingFieldName].sizing_field_beg_chest_compare_to.split('-');
				const begChestCompareSizingFieldNames = Object.keys(schema).filter(sizingFieldKey => {
					return sizingFieldKey.includes(begChestComparisonTypes[0]) || sizingFieldKey.includes(begChestComparisonTypes[1]) || sizingFieldKey.includes(begChestComparisonTypes[2]);
				});
				// Always show beg if conditions apply
				let clearBeg = true;
				if (newData[index]?.chest_size) {
					if (
						(newData[index][begChestCompareSizingFieldNames[0]] && (parseInt(newData[index][begChestCompareSizingFieldNames[0]]) - parseInt(newData[index].chest_size) >= (schema[sizingFieldName].sizing_field_beg_small ? parseInt(schema[sizingFieldName].sizing_field_beg_small) : 3)))
						|| (newData[index][begChestCompareSizingFieldNames[1]] && (parseInt(newData[index][begChestCompareSizingFieldNames[1]]) - parseInt(newData[index].chest_size) >= (schema[sizingFieldName].sizing_field_beg_small ? parseInt(schema[sizingFieldName].sizing_field_beg_small) : 3)))
						|| (newData[index][begChestCompareSizingFieldNames[2]] && (parseInt(newData[index][begChestCompareSizingFieldNames[2]]) - parseInt(newData[index].chest_size) >= (schema[sizingFieldName].sizing_field_beg_small ? parseInt(schema[sizingFieldName].sizing_field_beg_small) : 3)))
					) {

						setSchema((schema) => {
							schema[sizingFieldName].recommendations[index] = { recommendation_type: 'single', recommendation_text: schema[sizingFieldName].sizing_field_recommendation };
							return { ...schema };
						});
						clearBeg = false;

					}
				}
				if (clearBeg) {
					setSchema((schema) => {
						if (schema[sizingFieldName].recommendations[index]) {
							schema[sizingFieldName].recommendations[index] = undefined;
						}

						return { ...schema };
					});
				}
			} else {
				if(Array.isArray(schema[sizingFieldName]?.recommendations)){
					for (let recommendation of schema[sizingFieldName].recommendations) {
						if (!recommendation || recommendation?.recommendation_type == 'single') {
							continue;
						} else if (recommendation?.conditions.length) {
							const recommendedOptionIndex = schema[sizingFieldName].options.findIndex((option) => option.sizing_field_option_id === recommendation.sizing_field_recommendation_sizing_field_option_id);
							let setRecommendation = false;
							if (newData[index][recommendation.sizing_field_name] !== recommendation.recommendation_value) {
								for (let condition of recommendation.conditions) {
									if (newData[index][condition.first_sizing_field_name] && newData[index][condition.second_sizing_field_name]) {
										switch (condition.sizing_field_recommendation_condition_comparison_type) {
											case 'greater than':
												setRecommendation = parseInt(newData[index][condition.first_sizing_field_name]) > (parseInt(newData[index][condition.second_sizing_field_name]) + parseInt(condition.second_sizing_field_modifier));
												break;
											case 'greater than or equal to':
												setRecommendation = parseInt(newData[index][condition.first_sizing_field_name]) >= (parseInt(newData[index][condition.second_sizing_field_name]) + parseInt(condition.second_sizing_field_modifier));
												break;
											case 'equal to':
												setRecommendation = parseInt(newData[index][condition.first_sizing_field_name]) === (parseInt(newData[index][condition.second_sizing_field_name]) + parseInt(condition.second_sizing_field_modifier));
												break;
											case 'less than':
												setRecommendation = parseInt(newData[index][condition.first_sizing_field_name]) < (parseInt(newData[index][condition.second_sizing_field_name]) + parseInt(condition.second_sizing_field_modifier));
												break;
											case 'less than or equal to':
												setRecommendation = parseInt(newData[index][condition.first_sizing_field_name]) <= (parseInt(newData[index][condition.second_sizing_field_name]) + parseInt(condition.second_sizing_field_modifier));
										}

									} else {
										setRecommendation = false;
										break;
									}

									//If condition not met
									if (!setRecommendation) {
										break;
									}
								}
							}

							if (setRecommendation) {
								setSchema(prevSchema => {
									let newSchema = { ...prevSchema };
									if (!Object.prototype.hasOwnProperty.call(newSchema[sizingFieldName].options[recommendedOptionIndex], 'recommendations')) {
										newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations = {};
									}
									if (!Object.prototype.hasOwnProperty.call(newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations, index)) {
										newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations[index] = {};
									}

									newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations[index][recommendation.sizing_field_recommendation_id] = recommendation.sizing_field_recommendation_message ? recommendation.sizing_field_recommendation_message
										: newSchema[sizingFieldName].options[recommendedOptionIndex].sizing_field_option_label + ' recommended for measurement provided';

									return newSchema;
								});
							} else {
								setSchema(prevSchema => {
									let newSchema = { ...prevSchema };
									if (!Object.prototype.hasOwnProperty.call(newSchema[sizingFieldName].options[recommendedOptionIndex], 'recommendations')) {
										newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations = {};
									}
									if (!Object.prototype.hasOwnProperty.call(newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations, index)) {
										newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations[index] = {};
									}
									delete newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations[index][recommendation.sizing_field_recommendation_id];
									return newSchema;
								});
							}
						} else if (!newData[index][recommendation.first_sizing_field_name]) {
							setSchema(prevSchema => {
								const recommendedOptionIndex = schema[sizingFieldName].options.findIndex((option) => option.sizing_field_option_id === recommendation.sizing_field_recommendation_sizing_field_option_id);
								let newSchema = { ...prevSchema };
								if (!Object.prototype.hasOwnProperty.call(newSchema[sizingFieldName].options[recommendedOptionIndex], 'recommendations')) {
									newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations = {};
								}
								if (!Object.prototype.hasOwnProperty.call(newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations, index)) {
									newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations[index] = [];
								}

								newSchema[sizingFieldName].options[recommendedOptionIndex].recommendations[index].splice(recommendation.sizing_field_recommendation_id, 1);
								return newSchema;
							});
						}
					}
				}

			}
			if(Array.isArray(schema[sizingFieldName]?.conditions)){
				for (let condition of schema[sizingFieldName].conditions) {
					if (condition.sizing_field_option_id) {
						const conditionOptionIndex = schema[sizingFieldName].options.findIndex((option) => option.sizing_field_option_id === condition.sizing_field_option_id);
						disableFieldOrOptions(condition, index, schema, newData[index][condition.sizing_field_name] === schema[sizingFieldName].options[conditionOptionIndex].sizing_field_option_value);
					} else if (condition.comparison_sizing_field_id) {
						let conditionTrue = false;
						switch (condition.sizing_field_condition_comparison_type) {
							case 'greater than':
								conditionTrue = parseInt(newData[index][condition.sizing_field_name]) > (parseInt(newData[index][condition.comparison_sizing_field_name]) + parseInt(condition.sizing_field_condition_modifier));
								break;
							case 'greater than or equal to':
								conditionTrue = parseInt(newData[index][condition.sizing_field_name]) >= (parseInt(newData[index][condition.comparison_sizing_field_name]) + parseInt(condition.sizing_field_condition_modifier));
								break;
							case 'equal to':
								conditionTrue = parseInt(newData[index][condition.sizing_field_name]) === (parseInt(newData[index][condition.comparison_sizing_field_name]) + parseInt(condition.sizing_field_condition_modifier));
								break;
							case 'less than':
								conditionTrue = parseInt(newData[index][condition.sizing_field_name]) < (parseInt(newData[index][condition.comparison_sizing_field_name]) + parseInt(condition.sizing_field_condition_modifier));
								break;
							case 'less than or equal to':
								conditionTrue = parseInt(newData[index][condition.sizing_field_name]) <= (parseInt(newData[index][condition.comparison_sizing_field_name]) + parseInt(condition.sizing_field_condition_modifier));
						}
						disableFieldOrOptions(condition, index, schema, conditionTrue);
					} else if (condition.sizing_field_condition_value) {
						disableFieldOrOptions(condition, index, schema, newData[index][condition.sizing_field_name] == condition.sizing_field_condition_value);
					}
				}
			}
		}



		setData((data) =>
			data.map((item, i) => {
				return i === index ? { ...item, ...change } : item;
			}),
		);
	}, [data, schema]);

	/**
	 * Title/Info Copy
	 */

	const infoCopy = useMemo(() => {
		return (
			<div className={styles.infoCopy}>
				<h4>Edit Sizing</h4>
				<div dangerouslySetInnerHTML={{ __html: instructions }} >
				</div>
			</div>
		);
	}, [instructions]);

	const refresh = () => {
		setRefreshToken(Math.random());
	};

	return (
		<Modal
			active={active}
			onClose={confirmClose}
			isFullScreen={true}
			className={classNames({
				[styles.modal]: true,
				[styles.hideBackButton]: hideBackButton,
				[className]: !!className,
			})}>
			<div className={styles.modalBody}>
				<div className={styles.scrollContainer}>
					{dimensions.width > 1024 ? (
						<DesktopLayout
							quoteId={quoteId}
							copy={infoCopy}
							data={data}
							schema={schema}
							ranges={ranges}
							handleChange={handleChange}
							handleAddRow={handleAddRow}
							handleRemoveRow={handleRemoveRow}
							handleSubmit={handleSubmit}
							handleClose={confirmClose}
							handleUpload={handleUpload}
							incomplete={incomplete()}
							invalid={invalid}
							highlightIncomplete={(isEditable || isCSRUser) && highlightIncomplete}
							isSubmitting={isSubmitting}
							letteringFieldCount={letteringFieldCount}
							isEditable={isEditable}
							isSizeZero={isSizeZero}
							refresh={refresh}
							allowVirtualSizer={allowVirtualSizer}
							saveQuoteSizing={handleSubmit}
						/>
					) : (
						<MobileLayout
							quoteId={quoteId}
							copy={infoCopy}
							data={data}
							schema={schema}
							ranges={ranges}
							validation={validation}
							formatters={formatters}
							handleChange={handleChange}
							handleAddRow={handleAddRow}
							handleRemoveRow={handleRemoveRow}
							handleSubmit={handleSubmit}
							handleClose={confirmClose}
							handleUpload={handleUpload}
							incomplete={incomplete()}
							invalid={invalid}
							highlightIncomplete={(isEditable || isCSRUser) && highlightIncomplete}
							isSubmitting={isSubmitting}
							isEditable={isEditable}
							isSizeZero={isSizeZero}
							refresh={refresh}
							allowVirtualSizer={allowVirtualSizer}
							saveQuoteSizing={handleSubmit}
						/>
					)}
					<div ref={scrollNode} />
				</div>
				<div className={styles.actions}>
					{isEditable && (
						<AddRowButton
							onClick={handleAddRow}
							count={data.length}
						/>
					)}
					{(isEditable || isCSRUser) && (
						<SubmitButton
							onClick={handleSubmit}
							loading={isSubmitting}
						/>
					)}
				</div>
			</div>
			<div
				className={classNames({
					[styles.loader]: true,
					[styles.loaderActive]: isFetching,
				})}>
				<SpinnerIcon fill="#e87124" />
			</div>
			<ConfirmationModal
				active={confirmModalActive}
				title="Unsaved Changes"
				message="Are you sure you want to exit the sizing sheet? You have unsaved changes."
				onClose={async () => {
					setConfirmModalActive(false);
				}}
				onConfirm={async () => {
					setConfirmModalActive(false);
					await handleClose(onConfirmRefresh);
					setOnConfirmRefresh(false);
				}}
				mountOnEnter={false}
				unmountOnExit={false}
			/>
		</Modal>
	);
};

export default SizingModal;