import styles from './QuoteUpdateNotification.module.scss';
import date from "@utilities/date";

const QuoteUpdateNotification = ({ notification }) => {
	return (
		<div className={styles.quoteUpdateNotification}>
			<div
				className={styles.quoteUpdateNotificationPreview}
				dangerouslySetInnerHTML={{ __html: notification.quote_preview }}
			/>
			<div>
				<p className={styles.quoteUpdateNotificationTitle}>
					Quote <b>#{notification.quote_number}</b> Has Been Modified
				</p>
				<span className={styles.quoteUpdateNotificationSubtitle}>
					Changes were made to quote{' '}
					<b>#{notification.quote_number}</b> on {' '}
					{date(notification.notification_timestamp).format(
						'MM/DD/YY',
					)}{' '}
					at{' '}
					{date(notification.notification_timestamp).format('h:mm A')}{' '}
					by{' '}
					{
						notification.notification_meta
							?.changed_by_user_display_name
					}
					.
				</span>
				{!!notification.notification_meta?.comments && (
					<p className={styles.quoteUpdateNotificationComments}>
						{notification.notification_meta.comments}
					</p>
				)}
			</div>
		</div>
	);
};

export default QuoteUpdateNotification;
