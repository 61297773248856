import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from '@hooks/useForm';
import FieldRow from '@components/Field/FieldRow';
import TextField from '@components/TextField/TextField';
import FireDepartmentField from '@components/FireDepartmentField/FireDepartmentField';
import Button from '@components/Button/Button';
import fieldStyles from '@components/Field/Fields.module.scss';
import classNames from 'classnames';
import { schema } from './form';
import styles from './RequestQuoteForm.module.scss';
import SelectField from '@components/SelectField/SelectField';
import TextAreaField from '@components/TextAreaField/TextAreaField';
import ProductPicker from '../ProductPicker/ProductPicker';
import { API_ROOT } from '@config';
import Http from '@utilities/Http';
import { useSelector } from 'react-redux';

const purchaseOptions = [
	{
		label: '0 - 3 Months',
		value: '0 - 3 Months',
	},
	{
		label: '3 - 6 Months',
		value: '3 - 6 Months',
	},
	{
		label: '6 - 9 months',
		value: '6 - 9 months',
	},
	{
		label: '9 - 12 months',
		value: '9 - 12 months',
	},
	{
		label: '12+ months',
		value: '12+ months',
	},
];

const procurementOptions = [
	{
		label: 'Fire Department Budget',
		value: 'Fire Department Budget',
	},
	{
		label: 'Co-Operative Contract',
		value: 'Co-Operative Contract',
	},
	{
		label: 'Securing Grant Funding',
		value: 'Securing Grant Funding',
	},
];

const RequestQuoteForm = ({ data = null, onSubmit = async () => {} }) => {
	const [isMounted, setIsMounted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState(false);
	const states = useSelector((state) => state.staticData.states);
	const [filteredStates, setFilteredStates] = useState(states);
	const [isInitialized, setIsInitialized] = useState(false);
	const [formLoaded, setFormLoaded] = useState(false);
	const [populatedAddress, setPopulatedAddress] = useState({
		'address_street_1': null,
		'address_city': null,
		'address_state': null,
		'address_zip': null,
		'address_county': null,
		'address_country': 'US',
	});
	const countries = useSelector((state) => state.staticData.countries);
	const fireDepartmentCountries = useSelector((state) => state.staticData.fireDepartmentCountries);

	const [products, setProducts] = useState([]);

	/**
	 * Form data, validation, errors, etc
	 */

	const { formData, formErrors, getValidation, handleChange, setFormData } = useForm(schema, { ...data });

	useEffect(() => {
		if(formData?.address_country){
			setFilteredStates([...states.filter((state) => state.country_code === formData?.address_country)]);
		}else{
			setFilteredStates([...states]);
		}
	}, [formData?.address_country, states]);

	/**
	 * Form Submit
	 */

	const handleSubmit = useCallback(
		async () => {

			// convert selected products/quantites to string
			const products = formData.products
				.map((item) => {
					return `${item.label}${
						item.quantity ? ' x' + item.quantity : ''
					}`;
				})
				.join(', ');

			try {
				setIsSubmitting(true);
				await onSubmit({ ...formData, products, user_email: document.querySelector('[name="email"]').value });
				setSubmitError(false);
			} catch (error) {
				console.log(error);
				setSubmitError(
					error.response?.data?.meta?.message ||
						'Something went wrong. Please try again later.',
				);
			} finally {
				isMounted && setIsSubmitting(false);
			}
		},
		[getValidation, formData, isMounted, onSubmit],
	);

	/**
	 * Fetch Products
	 */

	const fetchProducts = useCallback(async () => {
		const { data } = await Http().get(`${API_ROOT}/product-lines/raq`);
		return data.raqProducts;
	}, []);

	function getCookie(cookieName) {
		let cookie = {};
		document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
		})
		return cookie[cookieName];
	}

	useEffect(() => {
		fetchProducts().then((products) => {
			setProducts(products);
		});
	}, [fetchProducts]);

	useEffect(() => {
		setIsMounted(true);
		setFormData((formData) => ({
			...formData,
			utk: getCookie('hubspotutk'),
		}))
		return () => {
			setIsMounted(false);
		};
	}, []);

	/**
	 * Handle Select Fire Department
	 */

	const handleSelectFireDepartment = useCallback(
		async (selected) => {
			// get details from hubspot (address properties)
			const {
				data: { company },
			} = await Http().get(
				`${API_ROOT}/fire-departments/details/${selected.fire_department_hubspot_id}?properties=address,company_shipping_address,city,state,zip,county,country,company_shipping_state,company_shipping_zip,company_shipping_city`,
			);

			const address = company?.properties?.company_shipping_address || company?.properties?.address;
			const city = company?.properties?.company_shipping_city || company?.properties?.city;
			const state = company?.properties?.company_shipping_state || company?.properties?.state;
			const zip = company?.properties?.company_shipping_zip || company?.properties?.zip;
			const county = company?.properties?.county;
			const country = company?.properties?.country || 'United States';


			const newPopulatedAddress = {
				address_street_1: address,
				address_city: city,
				address_state: state,
				address_zip: zip,
				address_county: county,
				address_country: country
			};

			setPopulatedAddress(newPopulatedAddress);
			let changes = [
				{
					target: {
						name: 'address_street_1',
						value: newPopulatedAddress.address_street_1 || '',
					},
				},
				{
					target: {
						name: 'address_city',
						value: newPopulatedAddress.address_city || '',
					},
				},
				{
					target: {
						name: 'address_state',
						value: newPopulatedAddress.address_state || '',
					},
				},
				{
					target: {
						name: 'address_county',
						value: newPopulatedAddress.address_county || '',
					},
				},
				{
					target: {
						name: 'address_zip',
						value: newPopulatedAddress.address_zip || '',
					},
				},
				{
					target: {
						name: 'address_country',
						value: newPopulatedAddress.address_country || '',
					},
				}
			];

			// populate or clear address fields
			handleChange(changes);
		},
		[handleChange],
	);

	/**
	 * Populate fire department details on load (if defined)
	 */

	useEffect(() => {
		if (!isInitialized && data.fire_department_hubspot_id) {
			handleSelectFireDepartment(data);
		}

		setIsInitialized(true);
	}, [data, handleSelectFireDepartment, isInitialized]);


	const validateFields = useCallback(async (event) => {

		const formErrors = await getValidation();

		if (!Object.values(formErrors).every((error) => error === null)) {
			event.preventDefault();
		}

		if (clearExtraFields()) {
			return true;
		} else {
			event.preventDefault();
		}

		return true;
	}, [getValidation, formData]);

	// If form data is changed updated embedded fields
	useEffect(() => {
		let productInterestedIn = document.querySelector('[name="raq_products_interested_in"]');
		if(formLoaded && productInterestedIn) {

			const products = formData.products
				.map((item) => {
					return `${item.label}${
						item.quantity ? ' x' + item.quantity : ''
					}`;
				})
				.join(', ');

			productInterestedIn.value = products;
			productInterestedIn.dispatchEvent(new Event('input', {bubbles: true}));


			let addressField = document.querySelector('[name="address"]');
			addressField.value = formData.address_street_1;
			addressField.dispatchEvent(new Event('input', {bubbles: true}));
			let cityField = document.querySelector('[name="city"]');
			cityField.value = formData.address_city;
			cityField.dispatchEvent(new Event('input', {bubbles: true}));
			let stateField = document.querySelector('[name="state"]');
			stateField.value = formData.address_state;
			stateField.dispatchEvent(new Event('input', {bubbles: true}));
			let countryField = document.querySelector('[name="country"]');
			countryField.value = formData.address_country;
			countryField.dispatchEvent(new Event('input', {bubbles: true}));
		}
	}, [formLoaded, formData]);


	// Replace hubspot products interested in field with product picker
	// And fire department field
	useEffect(() => {
		let productInterestedIn = document.querySelector('[name="raq_products_interested_in"]');
		if(formLoaded && productInterestedIn) {
			productInterestedIn.hidden = true;
			let productInterestedInLabel = productInterestedIn?.closest('.field').querySelector('label');
			productInterestedInLabel.hidden = true;

			var newDiv = document.getElementById('new_render');
			if(!newDiv) {
				newDiv = document.createElement('div');
				newDiv.id = 'new_render';
				productInterestedInLabel.parentNode.appendChild(newDiv);
			}

			ReactDOM.render(
				<div>
					{!formData.manual_department && (
						<FieldRow className={styles.fieldRow}>
							<FireDepartmentField
								label={
									<div>
										Fire Department Zip / Name
										<span
											className={styles.noFindLink}
											onClick={() => {
												setFormData((formData) => ({
													...formData,
													address_street_1: '',
													address_city: '',
													address_state: '',
													address_zip: '',
													address_county: '',
													address_country: 'US',
													manual_department:
														!formData.manual_department,
													fire_department_id: ''
												}));
												setPopulatedAddress({
													'address_street_1': null,
													'address_city': null,
													'address_state': null,
													'address_zip': null,
													'address_county': null,
													'address_country': 'US'
												});
											}}>
									Can&apos;t Find Department?
								</span>
									</div>
								}
								name="fire_department_id"
								value={formData.fire_department_id}
								error={formErrors.fire_department_id}
								onChange={handleChange}
								onSelect={handleSelectFireDepartment}
								defaultValues={[
									data?.fire_department_zip,
									data?.fire_department_name,
									data?.fire_department_country,
								]}
								className={'fire_department_field'}
								countries={fireDepartmentCountries}
							/>
						</FieldRow>
					)}
					{formData.manual_department && (
						<>
							<FieldRow className={styles.fieldRow}>
								<TextField
									label={
										<div>
											Fire Department Name*
											<span
												className={styles.noFindLink}
												onClick={() => {
													setFormData((formData) => ({
														...formData,
														address_street_1: '',
														address_city: '',
														address_state: '',
														address_zip: '',
														address_county: '',
														address_country: '',
														manual_department:
															!formData.manual_department,
														fire_department_id: ''
													}));
													setPopulatedAddress({
														'address_street_1': null,
														'address_city': null,
														'address_state': null,
														'address_zip': null,
														'address_county': null,
														'address_country': null
													});
												}}>
										Can&apos;t Find Department?
									</span>
										</div>
									}
									name="fire_department_name"
									value={formData.fire_department_name || ''}
									error={formErrors.fire_department_name}
									onChange={handleChange}
								/>
								<SelectField
									label="Fire Department Country*"
									value={formData.address_country || ''}
									name="address_country"
									onChange={handleChange}
									options={countries}
									labelField="country_name"
									valueField="country_code"
									className={classNames({ [styles.countryField]: true })}
									inputClassName={classNames({[styles.fullHeight]: true})}
									menuClassName={classNames({[styles.selectMenu]: true})}
								/>
							</FieldRow>

							<FieldRow className={styles.fieldRow}>
								<TextField
									name="address_street_1"
									label="Address*"
									value={formData.address_street_1}
									error={formErrors.address_street_1}
									onChange={handleChange}
									disabled={!!populatedAddress.address_street_1}
								/>
								<TextField
									label="City*"
									name="address_city"
									value={formData.address_city}
									error={formErrors.address_city}
									onChange={handleChange}
									disabled={!!populatedAddress.address_city}
								/>
							</FieldRow>
							<FieldRow className={styles.fieldRow}>
								<SelectField
									name="address_state"
									label="State / Province / Region*"
									value={formData.address_state}
									error={formErrors.address_state}
									onChange={handleChange}
									options={filteredStates}
									labelField="state_name"
									valueField="state_name"
									disabled={!!populatedAddress.address_state}
								/>
								<TextField
									label="Zip / Postal Code*"
									name="address_zip"
									value={formData.address_zip}
									error={formErrors.address_zip}
									onChange={handleChange}
									disabled={!!populatedAddress.address_zip}
								/>
								<TextField
									label="County*"
									name="address_county"
									value={formData.address_county}
									error={formErrors.address_county}
									onChange={handleChange}
									disabled={!!populatedAddress.address_county}
								/>
							</FieldRow>
						</>
					)}
					<ProductPicker
						name="products"
						label="Products Interested In"
						options={products}
						value={formData.products}
						error={formErrors.products}
						onChange={handleChange}
					/>
				</div>,
				newDiv
			);

			document.querySelector('input[type="submit"]').onclick = validateFields;
			document.querySelector('form').onsubmit = handleSubmit;
		}

		if(formLoaded && productInterestedIn) {
			let hiddenFields = document.querySelectorAll('fieldset div[style="display: none;"]');
			hiddenFields.forEach((fieldset) => {
				fieldset.closest('fieldset').style.display = 'none';
			})

		}
	}, [formLoaded, products, isInitialized, formData, formErrors,
		handleChange, countries, styles, filteredStates, validateFields]);

	const clearExtraFields = useCallback(async () => {
		let addressField = document.querySelector('[name="address_street_1"]');
		if(addressField) {
			addressField.disabled = true;
			addressField.dispatchEvent(new Event('input', {bubbles: true}));
			let cityField = document.querySelector('[name="address_city"]');
			cityField.disabled = true;
			cityField.dispatchEvent(new Event('input', {bubbles: true}));
			let zipField = document.querySelector('[name="address_zip"]');
			zipField.disabled = true;
			zipField.dispatchEvent(new Event('input', {bubbles: true}));
			let countyField = document.querySelector('[name="address_county"]');
			countyField.disabled = true;
			countyField.dispatchEvent(new Event('input', {bubbles: true}));
			let fireDepartmentNameField = document.querySelector('[name="fire_department_name"]');
			fireDepartmentNameField.disabled = true;
			fireDepartmentNameField.dispatchEvent(new Event('input', {bubbles: true}));
		}

		return true;
	}, []);

	const enableExtraFields = useCallback(async () => {
		let addressField = document.querySelector('[name="address_street_1"]');
		if(addressField) {
			addressField.disabled = false;
			addressField.dispatchEvent(new Event('input', {bubbles: true}));
			let cityField = document.querySelector('[name="address_city"]');
			cityField.disabled = false;
			cityField.dispatchEvent(new Event('input', {bubbles: true}));
			let zipField = document.querySelector('[name="address_zip"]');
			zipField.disabled = false;
			zipField.dispatchEvent(new Event('input', {bubbles: true}));
			let countyField = document.querySelector('[name="address_county"]');
			countyField.disabled = false;
			countyField.dispatchEvent(new Event('input', {bubbles: true}));
			let fireDepartmentNameField = document.querySelector('[name="fire_department_name"]');
			fireDepartmentNameField.disabled = false;
			fireDepartmentNameField.dispatchEvent(new Event('input', {bubbles: true}));
		}
	}, []);

	/*
		Cannot make this a child component like the contact form otherwise it will
		constantly re-render
	*/
	useEffect(() => {
		const script = document.createElement('script');
		script.src='https://js.hsforms.net/forms/embed/v2.js';
		document.body.appendChild(script);

		script.addEventListener('load', () => {
			// @TS-ignore
			if (window.hbspt) {
				// @TS-ignore
				window.hbspt.forms.create({
					region: 'na1',
					portalId: '4744594',
					formId: 'fdcc5bdd-25c5-4abc-8ae5-2617e0cefddb',
					target: '#hubspotForm',
					cssRequired: '',
					onFormReady: function($form) {
						setFormLoaded(true);
					},
				});
			}
		});
	}, [setFormLoaded]);

	window.addEventListener('message', event => {
		if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormFailedValidation') {
			enableExtraFields();
		}
	});

	return (
		<div className="hubspot-form">
			<div id="hubspotForm"></div>
		</div>
	);
};

export default RequestQuoteForm;
