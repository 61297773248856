import classNames from 'classnames/bind';

import { API_ROOT, SUSPENDER_OPTION_ID } from '@config';
import React, {useEffect, useMemo, useRef, useState} from "react";
import LetteringPreviewFields
	from "@components/LetteringSummary/components/LetteringPreviewFields/LetteringPreviewFields";
import { custom_x_default, custom_y_default } from '@components/Configurator/components/ConfiguratorBuild/components/ConfiguratorBuildInput.js';
import {useSelector} from "react-redux";

function ConfiguratorPreviewBody({
	quote,
	selectedProductOptionValues,
	imageOrientation,
 	outlineId,
	handleSavePosition = () => {},
	isSummary = false,
	setCustomPlacementsValid = () => {},
}) {
	const [customOutOfBounds, setCustomOutOfBounds] = useState([]);
	const [placingHalf, setPlacingHalf] = useState(null);
	const [touchedCustomPlacements, setTouchedCustomPlacements] = useState([]);
	const selectedImageRef = useRef(null);
	const imagePreviewRef = useRef(null);

	const {
		style: letteringStyleId,
		patch: letteringPatchId,
		color: outerShellColorId,
	} = useSelector((state) => state.staticData.letteringOptions);

	const letteringData = useMemo(()=>{
		return selectedProductOptionValues.filter(opVal=>opVal.lettering_placement_id);
	},[selectedProductOptionValues])

	function getProductOutlineImage() {
		let outlineImage = quote.outlineImages.find((outlineImage) => {
			return (
				outlineImage.product_outline_image_location === imageOrientation
			);
		});

		if (outlineImage) {
			return outlineImage.product_outline_image_file_url;
		}

		return false;
	}

	function optionValueIsSelected(productOptionValueId) {
		return (
			selectedProductOptionValues.findIndex(
				(productOptionValue) =>
					productOptionValue.product_option_value_id ===
					productOptionValueId,
			) >= 0
		);
	}

	function patchIsSelected(productOptionValueIdPatchColor, productOptionValueId) {
		return (
			selectedProductOptionValues.findIndex(
				(productOptionValue) =>
					productOptionValue.product_option_value_id_patch_color ===
					productOptionValueIdPatchColor
					&& productOptionValue.product_option_value_id === productOptionValueId,
			) >= 0
		);
	}


	function getProductImageParts() {
		let productImageParts = [];
		if(!Array.isArray(selectedProductOptionValues)){
			return productImageParts;
		}

		for (const [index, productOptionValue] of selectedProductOptionValues.entries()) {
			let image = getProductOptionValueImage(productOptionValue);
			let style = getProductOptionValueStyle(productOptionValue);
			if (!image || !style) {
				image = getProductOptionValueImage(productOptionValue, true);
				style = getProductOptionValueStyle(productOptionValue, true);
			} else if (
				productOptionValue.product_option_value_position_id &&
				productOptionValue.product_option_value_position_id !== '3' &&
				image.product_option_value_position_id !== productOptionValue.product_option_value_position_id
			) {
				//If style is for one side, and selected position is for opposite, flip the position
				let styleCopy = Object.assign({}, style);
				styleCopy.product_option_image_style_right = style.product_option_image_style_left;
				styleCopy.product_option_image_style_left = style.product_option_image_style_right;
				styleCopy.product_option_image_style_transform = style.product_option_image_style_transform ? null : 'scaleX(-1)';
				style = styleCopy;
			}

			if (image && style) {
				let sideConstraint = null;
				if (productOptionValue.quote_product_option_value_position_token){
					if(productOptionValue.quote_product_option_value_position_token.includes('left') && !productOptionValue.quote_product_option_value_position_token.includes('right')){
						if(productOptionValue.product_option_value_custom_position_side && productOptionValue.product_option_value_custom_position_side !== image.product_outline_image_location) {
							sideConstraint = 'right';
						} else {
							sideConstraint = 'left';
						}
					} else if(productOptionValue.quote_product_option_value_position_token.includes('right') && !productOptionValue.quote_product_option_value_position_token.includes('left')){
						if(productOptionValue.product_option_value_custom_position_side && productOptionValue.product_option_value_custom_position_side !== image.product_outline_image_location) {
							sideConstraint = 'left';
						} else {
							sideConstraint = 'right';
						}
					}

					// If image is for the back then flip the logic
					if(image.product_outline_image_location === 'back' && sideConstraint)  {
						if(sideConstraint === 'right') {
							sideConstraint = 'left'
						} else {
							sideConstraint = 'right'
						}
					}
				}

				if(productOptionValue.position_option_token === 'custom' && !productOptionValue.quote_product_option_value_custom_position_left) {
					productOptionValue.quote_product_option_value_custom_position_left = custom_x_default;
					productOptionValue.quote_product_option_value_custom_position_top = custom_y_default;
				}

				productImageParts.push({
					image: image.product_option_value_image_file_url,
					style: {
						...style,
						...(productOptionValue.quote_product_option_value_custom_position_top ?
								{product_option_image_style_top: productOptionValue.quote_product_option_value_custom_position_top,}
								: {}
						),
						...(productOptionValue.quote_product_option_value_custom_position_left
								? {product_option_image_style_left: productOptionValue.quote_product_option_value_custom_position_left,}
								: {}
						),
					},
					customizable:(productOptionValue.quote_product_option_value_custom_position_left || productOptionValue.quote_product_option_value_custom_position_top),
					optionValueIndex: index,
					newCustomize: productOptionValue.newCustomize && !touchedCustomPlacements?.includes(index.toString()),
					product_option_value_id: productOptionValue.product_option_value_id,
					sideConstraint: sideConstraint,
				});

			}
		}

		return productImageParts;
	}

	useEffect(()=>{
		setCustomPlacementsValid(customOutOfBounds.length === 0);
	},[customOutOfBounds, setCustomPlacementsValid])

	useEffect(()=>{
		let newOutOfBounds = [];
		let removed = [];
		selectedProductOptionValues.forEach((opVal, index)=>{
			if (opVal.product_option_value_position_id != '3'){
				removed.push(index.toString());
			}
			if(opVal.product_option_value_position_id == '3' && (opVal.newCustomize && !touchedCustomPlacements?.includes(index.toString())) || (opVal.quote_product_option_value_custom_position_left == custom_x_default || opVal.quote_product_option_value_custom_position_top == custom_y_default)){
				newOutOfBounds.push(index.toString());
			}
		});
		const newState = [...customOutOfBounds, ...newOutOfBounds].filter((item, index, self) => (item || item === "0") && self.indexOf(item) === index && !removed.includes(item));
		setCustomOutOfBounds(newState);
	},[selectedProductOptionValues, touchedCustomPlacements])

	useEffect(()=>{

		return ()=>{
			selectedImageRef.current = null;
			document.removeEventListener('mousemove', mouseUpdateImagePosition);
			document.removeEventListener('mouseup', stopMove);
		}
	},[])

	function startMove(e){
		if(!e.target.classList.contains('moveable') || selectedImageRef.current){
			return;
		}
		e.preventDefault();
		if(e.target.dataset.sideconstraint){
			console.log(e.target.dataset.sideconstraint);
			setPlacingHalf(e.target.dataset.sideconstraint);
		}else{
			setPlacingHalf(false);
		}
		const newTouchedCustomPlacements = [...touchedCustomPlacements, e.target.dataset.index.toString()].filter((item, index, self) => (item || item === "0") && self.indexOf(item) === index);
		setTouchedCustomPlacements(newTouchedCustomPlacements);

		document.addEventListener('mousemove', mouseUpdateImagePosition);
		document.addEventListener('mouseup', stopMove);
		selectedImageRef.current = e.target;

	}

	function stopMove(e){
		//TODO: save new position
		if(selectedImageRef.current){
			handleSavePosition(parseInt(selectedImageRef.current.dataset.index), parseFloat(selectedImageRef.current.style.top).toFixed(2), parseFloat(selectedImageRef.current.style.left).toFixed(2));
		}
		document.removeEventListener('mousemove', mouseUpdateImagePosition);
		document.removeEventListener('mouseup', stopMove);
		setPlacingHalf(false);
		selectedImageRef.current = null;
	}

	function mouseUpdateImagePosition(e){
		//TODO: convert to percentage

		if(!selectedImageRef.current || !imagePreviewRef.current){
			return;
		}
		const rect = imagePreviewRef.current.getBoundingClientRect();
		const mouseX = e.clientX - rect.left;
		const mouseY = e.clientY - rect.top;
		const imageWidth = selectedImageRef.current.clientWidth;
		const imageHeight = selectedImageRef.current.clientHeight;
		const imagePreviewWidth = imagePreviewRef.current.clientWidth;
		const imagePreviewHeight = imagePreviewRef.current.clientHeight;

		let top = null;
		let left = null;
		top = mouseY - (imageHeight / 2);
		left = mouseX - (imageWidth / 2);

		let leftMinBoundary = 0;
		let leftMaxBoundary = imagePreviewWidth - imageWidth;
		const constraint = selectedImageRef.current.dataset.sideconstraint
		if(constraint ){
			if(constraint === 'right'){
				leftMinBoundary = 0;
				leftMaxBoundary = (imagePreviewWidth / 2);
			}else if(constraint === 'left'){
				leftMinBoundary = (imagePreviewWidth / 2) - imageWidth;
				leftMaxBoundary = imagePreviewWidth - imageWidth;
			}
		}

		if(top < 0 || left <  leftMinBoundary || top > imagePreviewHeight - imageHeight || left > leftMaxBoundary){
			setCustomOutOfBounds(customOutOfBounds=>[...customOutOfBounds, selectedImageRef.current.dataset.index].filter((value, index, array) => {
				return (value || value === "0") && array.indexOf(value) === index;
			  }))
		}else{
			setCustomOutOfBounds(customOutOfBounds=>[...customOutOfBounds.filter((index)=>index !== selectedImageRef.current.dataset.index)].filter((value, index, array) => {
				return array.indexOf(value) === index;
			  }))
		}


		top = (top / imagePreviewHeight) * 100;
		left = (left / imagePreviewWidth) * 100;

		selectedImageRef.current.style.top = `${top}%`;
		selectedImageRef.current.style.left = `${left}%`;
	}

	function getProductOptionValueImage(
		productOptionValue,
		getMirrorImage = false,
	) {
		let image = null;
		const imagesProp = getMirrorImage ? 'mirrorImages' : 'images';

		//Filter out images by orientation
		let productOptionValueImagesFiltered = productOptionValue[imagesProp]
			? productOptionValue[imagesProp].filter(
					(productOptionValueImage) => {
						if(productOptionValue.product_option_value_custom_position_side) {
							return productOptionValue.product_option_value_custom_position_side === imageOrientation;
						}

						return productOptionValueImage.product_outline_image_location === imageOrientation;
					},
			  )
			: [];

		// If product option type uses quantity
		// check for quantity requirement on images that match
		// otherwise use one that is null
		if(productOptionValue.product_option_type_id === '5') {
			productOptionValueImagesFiltered = productOptionValueImagesFiltered.filter((productOptionValueImage) => {
				return productOptionValueImage.product_option_value_image_quantity == productOptionValue.quote_product_option_value_quantity
					|| productOptionValueImage.product_option_value_image_quantity == null;
			});
		}

		if (productOptionValueImagesFiltered.length) {
			//Search for image combination with other selected options
			const combinationOptionValueImage =
				productOptionValueImagesFiltered.find(
					(productOptionValueImage) => {
						if (
							productOptionValueImage.product_option_value_combo_id
						) {

							// If it is a lettering patch, check the outer shell color
							if(productOptionValueImage.product_option_id === letteringPatchId?.toString()) {
								return patchIsSelected(productOptionValueImage.product_option_value_combo_id, productOptionValue.product_option_value_id);
							}

							return optionValueIsSelected(
								productOptionValueImage.product_option_value_combo_id,
							);
						}

						return false;
					},
				);

			const dualCombinationOptionValueImage =
				productOptionValueImagesFiltered.find(
					(productOptionValueImage) => {
						if (
							productOptionValueImage.product_option_value_combo_id &&
							productOptionValueImage.product_option_value_combo_id_2
						) {
							return (
								optionValueIsSelected(
									productOptionValueImage.product_option_value_combo_id,
								) &&
								optionValueIsSelected(
									productOptionValueImage.product_option_value_combo_id_2,
								)
							);
						}

						return false;
					},
				);

			//Use combination image
			if (dualCombinationOptionValueImage) {
				image = dualCombinationOptionValueImage;
			} else if (combinationOptionValueImage) {
				image = combinationOptionValueImage;
			} else {
				//Search for default option value image without combination value selected
				const optionValueImage = productOptionValueImagesFiltered.find(
					(productOptionValueImage) => {
						return !productOptionValueImage.product_option_value_combo_id;
					},
				);

				//Use default option value image
				if (optionValueImage) {
					image = optionValueImage;
				} else {
					//Disable default image
					image = null;
				}
			}
		}

		return image;
	}

	function getProductOptionValueStyle(
		productOptionValue,
		getMirrorStyle = false,
	) {
		const stylesProp = getMirrorStyle ? 'mirrorImageStyles' : 'imageStyles';

		let productOptionImageStylesFiltered = productOptionValue[stylesProp];

		if(productOptionValue.product_option_type_id === '5') {
			productOptionImageStylesFiltered = productOptionImageStylesFiltered?.filter((productOptionImageStyle) => {
				return productOptionImageStyle.product_option_value_image_quantity == productOptionValue.quote_product_option_value_quantity
					|| productOptionImageStyle.product_option_value_image_quantity == null;
			});
		}

		//Search for dual combination style
		let style = productOptionImageStylesFiltered?.find(
			(productOptionImageStyle) => {
				if(productOptionValue.product_option_value_custom_position_side) {
					return (
						productOptionValue.product_option_value_custom_position_side ===
						imageOrientation &&
						optionValueIsSelected(
							productOptionImageStyle.product_option_value_combo_id,
						) &&
						optionValueIsSelected(
							productOptionImageStyle.product_option_value_combo_id_2,
						)
					);
				}

				return (
					productOptionImageStyle.product_outline_image_location ===
						imageOrientation &&
					optionValueIsSelected(
						productOptionImageStyle.product_option_value_combo_id,
					) &&
					optionValueIsSelected(
						productOptionImageStyle.product_option_value_combo_id_2,
					)
				);
			},
		);

		if (!style) {
			//Search for combination style
			style = productOptionImageStylesFiltered?.find(
				(productOptionImageStyle) => {
					if(productOptionValue.product_option_value_custom_position_side) {
						return (
							productOptionValue.product_option_value_custom_position_side ===
							imageOrientation &&
							optionValueIsSelected(
								productOptionImageStyle.product_option_value_combo_id,
							)
						)
					}

					return (
						productOptionImageStyle.product_outline_image_location ===
							imageOrientation &&
						optionValueIsSelected(
							productOptionImageStyle.product_option_value_combo_id,
						)
					);
				},
			);
		}

		//If style not found for combination, look for default style
		if (!style) {
			style = productOptionImageStylesFiltered?.find(
				(productOptionImageStyle) => {
					if(productOptionValue.product_option_value_custom_position_side) {
						return (
							productOptionValue.product_option_value_custom_position_side ===
							imageOrientation &&
							productOptionImageStyle.product_option_value_combo_id ===
							null
						);
					}

					return (
						productOptionImageStyle.product_outline_image_location ===
							imageOrientation &&
						productOptionImageStyle.product_option_value_combo_id ===
							null
					);
				},
			);
		}

		//If not product option value default style, look for product option default style
		if (!style) {
			style = productOptionImageStylesFiltered?.find(
				(productOptionImageStyle) => {
					if(productOptionValue.product_option_value_custom_position_side) {
						return (
							productOptionValue.product_option_value_custom_position_side ===
							imageOrientation &&
							productOptionImageStyle.product_option_value_combo_id ===
							null
						);

					}

					return (
						productOptionImageStyle.product_outline_image_location ===
							imageOrientation &&
						productOptionImageStyle.product_option_value_combo_id ===
							null
					);
				},
			);
		}

		if (style && getMirrorStyle) {
			let styleCopy = Object.assign({}, style);
			styleCopy.product_option_image_style_right =
				style.product_option_image_style_left;
			styleCopy.product_option_image_style_left =
				style.product_option_image_style_right;
			styleCopy.product_option_image_style_transform =
				style.product_option_image_style_transform
					? null
					: 'scaleX(-1)';
			style = styleCopy;
		}

		return style;
	}

	return (
		<div
		ref={imagePreviewRef}
			className={classNames(
				'configurator-preview-wrapper',
				'margin-centered',
				{ coat: quote.product_id === '1' },
				{ pants: quote.product_id === '2' },
				{ pants: quote.product_id === '3' },
				{outOfBounds: customOutOfBounds?.filter(item=> item || item === "0").length > 0},
				{coverRight: placingHalf === 'right'},
				{coverLeft: placingHalf === 'left'},
				{
					suspenders: selectedProductOptionValues.find(
						(optionValue) => {
							return (
								optionValue['product_option_id'] ===
								SUSPENDER_OPTION_ID
							);
						},
					),
				},
			)}>
			{(['1', '3'].includes(quote.product_id) && letteringData?.length>0 && isSummary) &&
				<LetteringPreviewFields showPlacements={false}
										data={letteringData}
										showLettering={imageOrientation === 'back'}
										outlineId={outlineId}
										isSummary={true}
				/>
			}
			{getProductOutlineImage() ? (
				<>
					<img
						className="base-image no-pointer"
						src={`${API_ROOT}${getProductOutlineImage()}`}
					/>
					{getProductImageParts()?.map((imagePart, index) => {
						const top = imagePart.style
							.product_option_image_style_top
							? imagePart.style.product_option_image_style_top +
							  '%'
							: null;
						const left = imagePart.style
							.product_option_image_style_left
							? imagePart.style.product_option_image_style_left +
							  '%'
							: null;
						const bottom = imagePart.style
							.product_option_image_style_bottom
							? imagePart.style
									.product_option_image_style_bottom + '%'
							: null;
						const right = imagePart.style
							.product_option_image_style_right
							? imagePart.style.product_option_image_style_right +
							  '%'
							: null;
						const width = imagePart.style
							.product_option_image_style_width
							? imagePart.style.product_option_image_style_width +
							  '%'
							: null;
						const zIndex =
							imagePart.style.product_option_image_style_z_index;
						const transform =
							imagePart.style
								.product_option_image_style_transform;

						return (
							//TODO: multiple image parts could habe the same otion value id and this will break

							<img
							data-sideconstraint={imagePart.sideConstraint}
							data-index={imagePart.optionValueIndex}
							data-optionid={imagePart.product_option_value_id}
								key={index}
								className={classNames("image-part",
								{"no-pointer":!imagePart.customizable || isSummary},
								{"moveable":imagePart.customizable && !isSummary},
								{"out-of-bounds":(customOutOfBounds.includes(imagePart.optionValueIndex.toString()))},
								)}
								onMouseDownCapture={startMove}
								onMouseUp={stopMove}
								src={`${API_ROOT}${imagePart.image}`}
								style={{
									...(top != null ? { top } : {}),
									...(left != null ? { left } : {}),
									...(bottom != null ? { bottom } : {}),
									...(right != null ? { right } : {}),
									...(width != null ? { width } : {}),
									...(zIndex != null
										? { zIndex: parseInt(zIndex * 100) }
										: {}),
									...(transform != null ? { transform } : {}),
								}}
							/>
						);
					})}
				</>
			) : null}
		</div>
	);
}

export default ConfiguratorPreviewBody;
