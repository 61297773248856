
import { formatCurrency } from '@utilities/currency';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './OrderItemDetails.module.scss';
import {getQuoteTotalPrice, splitQuotePrices} from '@utilities/pricing';
import OrderItemDetailCategories from './OrderItemDetailCategories';
import {useMemo, useState} from "react";

const OrderItemDetails = ({
	quote,
	compact = false,
	siblingQuote = null,
	allSiblings = null,
}) => {
	
	const demoModeActive = useSelector((state) => state.demoMode.active);
	const allSiblingQuotes= useMemo(()=>{
		return quote.allSiblingQuotes || allSiblings}
	, [quote.allSiblingQuotes, allSiblings]);
	/**
	 * Expedite Percentage
	 */

	const expeditePercentage = useSelector(
		(state) => state.staticData.orderSettings.custom_expedite_percentage,
	);

	function getTotal() {
		let total =  getQuoteTotalPrice(
			{
				...quote,
			},
			siblingQuote
				? {
					...siblingQuote,
				}
				: null,
		);
	}

	const quoteSizingQuantity = useMemo(() => {
		if(quote) {
			return quote.product_line_id ? quote.sizing_quantity : quote.quote_product_quantity;
		}
		return 0;
	}, [quote]);

	const siblingSizingQuantity = useMemo(() => {
		if(siblingQuote) {
			return siblingQuote.product_line_id ? siblingQuote.sizing_quantity : siblingQuote.quote_product_quantity;
		}
		return 0;
	}, [siblingQuote]);

	const quoteQuantity = useMemo(() => {
		if(quote) {

			switch (quote.product_id) {
				case '1':
					return quoteSizingQuantity > 0 ? quote.sizing_quantity_coat : 1;
				case '2':
					return quoteSizingQuantity > 0 ? quote.sizing_quantity_pants : 1;
				case '3':
					return quoteSizingQuantity > 0 ? quote.sizing_quantity_coveralls : 1;
				default:
					if(allSiblingQuotes) {
						let quantity = quote.quote_product_quantity;
						quantity = Number(quantity) + allSiblingQuotes.reduce((total, current) => total = total + Number(current.quote_product_quantity), 0);
						return quoteSizingQuantity > 0 ? quantity : 1;
					}
					return quoteSizingQuantity > 0 ? quote.sizing_quantity : 1;
			}
		}

		return 1;
	}, [allSiblingQuotes, quote, quoteSizingQuantity]);

	const siblingQuoteQuantity = useMemo(() => {
		if(siblingQuote) {
			switch (siblingQuote.product_id) {
				case '1':
					return siblingSizingQuantity > 0 ? siblingQuote.sizing_quantity_coat : 1;
				case '2':
					return siblingSizingQuantity > 0 ? siblingQuote.sizing_quantity_pants : 1;
				case '3':
					return siblingSizingQuantity > 0 ? siblingQuote.sizing_quantity_coveralls : 1;
				default:
					return siblingSizingQuantity > 0 ? siblingQuote.sizing_quantity : 1;
			}
		}

		return 1;
	}, [siblingQuote, siblingSizingQuantity]);

	return (
		<div
			className={classNames({
				[styles.orderItemDetailsContainer]: true,
				[styles.compact]: compact,
				[styles.demoMode]: demoModeActive,
			})}>
			<OrderItemDetailCategories quote={quote} />
			{siblingQuote && quote.product_configurable === '1' && (
				<OrderItemDetailCategories quote={siblingQuote} />
			)}
			{allSiblingQuotes && quote.product_configurable !== '1' &&
				allSiblingQuotes?.map((obj) => {
					return (
						<OrderItemDetailCategories
							key={obj.quote_id}
							quote={obj}
						/>
					);
				})}
			{!demoModeActive && (
				<section className={styles.orderItemDetailsPricingSection}>
					<h5>Total Pricing</h5>
					<ul>
						{quote.product_configurable === '1' && (
							<>
								<li>
									<span className={styles.optionList}>
										{quote.product_title} List: {quoteQuantity} x {formatCurrency(
										getQuoteTotalPrice({
												...quote,
												quote_discount: 0,
												quote_expedite: false,
												quote_net_price_adjustment: 0,
											}) / quoteQuantity,
										)}
									</span>
									{!!parseFloat(quote.quote_discount || 0) && (
										<span className={styles.optionMiddle}>
														{quote.quote_discount_percentage}% Discount
													</span>
									)}
									<span className={styles.optionNet}>
										{quote.quote_net_price_adjustment ? 'Fire-Dex Approved' : ''} {quote.product_title} Net: {quoteQuantity} x {formatCurrency(
											getQuoteTotalPrice({
												...quote,
											}) / quoteQuantity,
										)}
									</span>
								</li>
								{siblingQuote && siblingQuoteQuantity > 0 && (
									<li>
										<span className={styles.optionList}>
											{siblingQuote.product_title} List: {siblingQuoteQuantity} x {formatCurrency(
											getQuoteTotalPrice({
												...siblingQuote,
												quote_discount: 0,
												quote_expedite: false,
												quote_net_price_adjustment: 0,
											}) / siblingQuoteQuantity,
										)}
										</span>
											{!!parseFloat(siblingQuote.quote_discount || 0) && (
												<span className={styles.optionMiddle}>
															{siblingQuote.quote_discount_percentage}% Discount
														</span>
											)}
											<span className={styles.optionNet}>
											{siblingQuote.quote_net_price_adjustment ? 'Fire-Dex Approved' : ''} {siblingQuote.product_title} Net: {siblingQuoteQuantity} x {formatCurrency(
												getQuoteTotalPrice({
													...siblingQuote,
												}) / siblingQuoteQuantity,
											)}
										</span>
									</li>
								)}
							</>
						)}
						{quote.product_configurable !== '1' && (
							splitQuotePrices(quote).map((splitPrice) =>  {
								return (
									<li key={splitPrice.key}>
										<span className={styles.optionList}>
											List: {splitPrice.quantity} x {formatCurrency(splitPrice.price)}
										</span>
										{!!parseFloat(quote.quote_discount || 0) && (
											<span className={styles.optionMiddle}>
												{quote.quote_discount_percentage}% Discount
											</span>
										)}
										<span className={styles.optionNet}>
											{quote.quote_net_price_adjustment ? 'Fire-Dex Approved' : ''} Net: {splitPrice.quantity} x {formatCurrency(splitPrice.net)}
										</span>
									</li>
								);
							})
						)}
						{!!quote.quote_expedite && (
							<li>
								<div>
									<span className={styles.optionTitle}>
										Custom Expedite
									</span>
								</div>
								<span className={styles.optionPrice}>
									{formatCurrency(
										getQuoteTotalPrice(
											{
												...quote,
												quote_discount: 0,
												quote_expedite: false,
											},
											siblingQuote
												? {
														...siblingQuote,
														quote_discount: 0,
														quote_expedite: false,
												  }
												: null,
										) *
											(parseFloat(
												expeditePercentage || 0,
											) /
												100),
									)}
								</span>
							</li>
						)}
						<li>
							<span className={styles.optionList}>
								Total List: {formatCurrency(
								getQuoteTotalPrice(
									{
										...quote
									},
									siblingQuote
										? {
										...siblingQuote
									}
										: null,
									false,
									true,
									false,
									true,
									false,
									true
								)
							)}
							</span>
							{!!parseFloat(quote.quote_discount || 0) && (
								<span className={styles.optionMiddle}>
									{quote.quote_discount_percentage}% Discount
								</span>
							)}
							<span className={styles.optionNet}>
								{quote.quote_net_price_adjustment ? 'Fire-Dex Approved ' : ''}Total Net: {formatCurrency(
									getQuoteTotalPrice(
										{
											...quote,
										},
										siblingQuote
											? {
												...siblingQuote,
											}
											: null,
									)
								)}
							</span>
						</li>
					</ul>
				</section>
			)}
		</div>
	);
};

export default OrderItemDetails;
