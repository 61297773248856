import classNames from 'classnames';
import { useMemo, useRef, useEffect } from 'react';
import IOActions from '../IOActions/IOActions';
import styles from './DesktopLayout.module.scss';
import FieldRow from './FieldRow';
import SizerInviteModal from '@components/SizerInviteModal/SizerInviteModal';


const TableForm = ({
	quoteId,
	schema = {},
	ranges = {},
	data = {},
	copy = null,
	handleChange = () => {},
	handleRemoveRow = null,
	handleClose = () => {},
	handleUpload = () => {},
	highlightIncomplete = false,
	incomplete = [],
	invalid = [],
	letteringFieldCount = 1,
	isEditable = true,
	isSizeZero = {},
	refresh = () => {},
	allowVirtualSizer = false,
	saveQuoteSizing = async () => {},
}) => {
	const leftColumnNode = useRef(null);
	const rightColumnNode = useRef(null);
	// console.log('TableForm', schema);
	/**
	 * Chunk fields by product_id
	 */
	const sections = useMemo(() => {
		const fieldGroups = Object.keys(schema).reduce(
			(fieldGroups, fieldName) => {
				const field = schema[fieldName];
				const productId = field.product_id || 0; // 0 for null

				if (!fieldGroups[productId]) {
					fieldGroups[productId] = {
						title: field.product_title,
						fields: [],
					};
				}
				fieldGroups[productId].fields.push(field);

				return fieldGroups;
			},
			{},
		);

		return fieldGroups;
	}, [schema]);

	/**
	 * Column width adjustments
	 */

	const columnSize = useMemo(() => {
		let percent = 20;

		percent += 5 * letteringFieldCount;

		return { minWidth: `${percent}%`, maxWidth: `${percent}%` };
	}, [letteringFieldCount]);

	const columnSizeRight = useMemo(() => {
		let percent = 20;

		percent += 5 * letteringFieldCount;

		percent = 100 - percent;
		return { minWidth: `calc(${percent - 15}% - 22px)`, maxWidth: `calc(${percent}% - 22px)` };
	}, [letteringFieldCount]);

	/**
	 * Force horiz. scrollbar in left column if present in right column
	 */

	let leftColumnStyle = {};
	let rightColumnStyle = {};

	// if (
	// 	rightColumnNode.current?.scrollWidth >
	// 	rightColumnNode.current?.clientWidth
	// ) {
	// 	leftColumnStyle = { overflowX: 'scroll' };
	// }
	//
	// if (
	// 	leftColumnNode.current?.scrollWidth >
	// 	leftColumnNode.current?.clientWidth
	// ) {
	// 	rightColumnStyle = { overflowX: 'scroll' };
	// }

	return (
		<div className={styles.tableFormWrapper}>
			<div className={styles.tableForm}>
				<div className={classNames(styles.leftColumn)} style={columnSize}>
					{copy}
				</div>
				<div className={classNames(styles.rightColumn)} style={columnSizeRight}>
					<IOActions
						quoteId={quoteId}
						handleClose={handleClose}
						handleUpload={handleUpload}
						isEditable={isEditable}
						refresh={refresh}
						allowVirtualSizer={allowVirtualSizer}
						saveQuoteSizing={saveQuoteSizing}
					/></div>
			</div>
			<div className={styles.tableForm}>
				<div className={classNames(styles.leftColumn, styles.leftColumnTable)} style={columnSize}>
					<div
						ref={leftColumnNode}
						className={styles.fieldGroups}
						style={leftColumnStyle}>
						{Object.keys(sections)
							.filter((productId) => productId === '0')
							.map((productId) => (
								<FieldRow
									quoteId={quoteId}
									key={productId}
									fields={sections[productId].fields}
									schema={schema}
									ranges={ranges}
									data={data}
									handleChange={handleChange}
									handleRemoveRow={handleRemoveRow}
									incomplete={incomplete}
									invalid={invalid}
									highlightIncomplete={highlightIncomplete}
									isEditable={isEditable}
									isSizeZero={isSizeZero}
								/>
							))}
					</div>
				</div>
				<div className={classNames(styles.rightColumn)} style={columnSizeRight}>
					<div
						ref={rightColumnNode}
						className={styles.fieldGroups}
						style={rightColumnStyle}>
						{Object.keys(sections)
							.filter((productId) => productId !== '0')
							.map((productId, index) => (
								<div key={productId} className={styles.fieldGroup}>
									<div className={styles.fieldGroupTitle}>
										{sections[productId].title} Sizes (
										{
											data.reduce((indexed, value) => {
												if(productId === '1') {
													return indexed + (parseInt(value['quantity_coat']) || 0);
												} else if(productId === '2') {
													return indexed + (parseInt(value['quantity_pants']) || 0);
												} else if(productId === '3') {
													return indexed + (parseInt(value['quantity_coveralls']) || 0);
												} else {
													return indexed + 1;
												}
											}, 0)
										})
									</div>
									<FieldRow
										quoteId={quoteId}
										key={'prod-'+productId}
										fields={sections[productId].fields}
										schema={schema}
										ranges={ranges}
										data={data}
										handleChange={handleChange}
										incomplete={incomplete}
										invalid={invalid}
										highlightIncomplete={highlightIncomplete}
										isEditable={isEditable}
										row={index}
										isSizeZero={isSizeZero}
									/>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableForm;
